@tailwind base;
@tailwind components;
@tailwind utilities;

input[type=file] {
    @apply p-0 block w-full text-sm text-gray-900 bg-gray-50 rounded border cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
}

.angled-after:after {
    background: inherit;
    bottom: 0;
    content: '';
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    transform-origin: 100%;
    z-index: -2;
}

.angled-before {
    position: relative;
    z-index: 1;
}

.angled-before::before {
    background: inherit;
    bottom: 0;
    content: '';
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    transform-origin: 100%;
    z-index: -1;
}



.ruka:hover .img-default {
    visibility: hidden;
    width: 0;
}

.ruka:hover .img-top {
    visibility: visible !important;
    display: inline-block;
    z-index: 2;
    transform: scale(1.1)
}

.ruka:hover p {
    margin-top: 154px;
   @apply text-blue-500
}

.gradient-pages {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 40%, rgba(224,242,254,1) 100%);
}

.admin input:focus {
    outline-offset: 0;
    box-shadow: none;
}

.z-9999 {
    z-index: 9999;
}

.btn-primary {
    background-color: firebrick;
}

.note-modal-footer {
    height: 50px;
}

.z-21 {
    z-index: 21 !important;
}

.error-editor .note-editor {
    border-color: #d32f2f;
}

.error-editor .MuiFormHelperText-root {
    color: #d32f2f;
}

textarea {
    resize: none;
}

.container {
    max-width: 1100px !important;
}

.content-bg {
    background-image: url("./img/bg-subpage.jpg");
    background-repeat: repeat-y;
    background-size: contain;
}

ul {
    @apply list-disc pl-4
}

ol {
    @apply list-decimal pl-4
}

.content hr {
    @apply border-t border-solid border-blue-500 mx-auto w-1/2 my-8
}

.btn {
    @apply rounded uppercase font-semibold inline-block
}

.btn-sm {
    @apply px-2 py-1
}

.btn-md {
    @apply px-4 py-2
}

.btn-xl {
    @apply px-12 py-4
}

.primary {
    @apply bg-blue-500 hover:bg-blue-800 text-white
}


.attachment-item .remove {
        display: none;
}

.attachment-item:hover .remove {
    display: inline-block
}
