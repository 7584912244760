
input[type=file]::file-selector-button {
    background-color: #07a3e0;
    border: 0;
    padding: 0.625rem 1rem 0.625rem 2rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: white;
    cursor: pointer;
    margin-inline-start: -1rem;
    margin-inline-end: 1rem;
}


input[type=file]::file-selector-button:hover {
    background-color: #0085c2;
}

input[type=file]::-ms-browse {
    background-color: #07a3e0;
    border: 0;
    padding: 0.625rem 1rem 0.625rem 2rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: white;
    cursor: pointer;
    margin-inline-start: -1rem;
    margin-inline-end: 1rem;
}

input[type=file]::-webkit-file-upload-button {
    background-color: #07a3e0;
    border: 0;
    padding: 0.625rem 1rem 0.625rem 2rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: white;
    cursor: pointer;
    margin-inline-start: -1rem;
    margin-inline-end: 1rem;
}

input[type=file]::-webkit-file-upload-button:hover {
    background-color: #0085c2;
}

input[type=file]::-ms-browse:hover {
    background-color: #0085c2;
}
